<template>
  <div>
    <div class="content">
      <div class="card" style="display: flex">
        <div class="middle">
          <el-form class="form" :inline="true" label-width="100px">
            <el-form-item label="选择门店">
              <el-select v-model="hierarchy_id">
                <template v-for="item in merchantList">
                  <el-option
                    :label="item.hierarchy_name"
                    :value="item.hierarchy_id"
                    :key="item.hierarchy_id"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="年份">
              <el-date-picker v-model="year" type="year" placeholder="选择年">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">搜索</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <div class="table">
      <el-table
        :data="tableData"
        stripe
        height="700"
        v-loading="loading"
        element-loading-text="查询数据较多，请稍等片刻"
        element-loading-spinner="el-icon-loading"
        element-loading-background="white"
      >
        <el-table-column
          align="center"
          prop="month"
          label="月份"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="status_text"
          label="状态"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="num"
          label="数量"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="gold_weight"
          label="净金重"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="weight"
          label="总重"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="price_tag"
          label="标签价"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="total_cost_price"
          label="成本价"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="realname"
          label="操作人"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="create_time"
          label="操作时间"
        ></el-table-column>
        <el-table-column
          align="center"
          fixed="right"
          prop="operation"
          label="操作"
          width="200"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="
                scope.row.status_text === '未月结' ||
                scope.row.status_text === '月结解除'
              "
              @click="handelSettlement(10, scope.row)"
              >月结锁定</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.status_text === '月结锁定'"
              @click="handelSettlement(20, scope.row)"
              >月结解除
            </el-button>
            <el-button type="text" @click="handleDetail(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column></el-table
      >
    </div>
  </div>
</template>

<script>
import {
  getSettlementList,
  handelSettlement,
} from "@/api/monthSettle/index.js";
import { getShopAndMerchantReq as getMerchantListReq } from "@/api/order/offlineOrder/add.js";

import storage from "good-storage";

export default {
  data() {
    return {
      cond: {
        page: 1,
        page_num: 50,
      },
      formInline: {},
      merchantList: [],
      hierarchy_id: 0,
      year: 0,
      tableData: [
        {
          month: "1月",
          status_text: "未月结",
          key: 1,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "2月",
          status_text: "未月结",
          key: 2,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "3月",
          status_text: "未月结",
          key: 3,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "4月",
          status_text: "未月结",
          key: 4,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "5月",
          status_text: "未月结",
          key: 5,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "6月",
          status_text: "未月结",
          key: 6,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "7月",
          status_text: "未月结",
          key: 7,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "8月",
          status_text: "未月结",
          key: 8,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "9月",
          status_text: "未月结",
          key: 9,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "10月",
          status_text: "未月结",
          key: 10,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "11月",
          status_text: "未月结",
          key: 11,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "12月",
          status_text: "未月结",
          key: 12,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
      ],
      loading: false,
      rowList: [],
    };
  },
  created() {
    this.hierarchy_id = storage.get("userInfo").hierarchy_id;
    this.year = new Date();
    this.getSettlementList();
    this.getMerchantList();
  },
  methods: {
    getMerchantList() {
      getMerchantListReq(0).then((res) => {
        if (res.code === 1) {
          this.merchantList = res.data;
        }
      });
    },
    getSettlementList() {
      this.loading = true;
      this.tableData = [
        {
          month: "1月",
          status_text: "未月结",
          key: 1,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "2月",
          status_text: "未月结",
          key: 2,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "3月",
          status_text: "未月结",
          key: 3,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "4月",
          status_text: "未月结",
          key: 4,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "5月",
          status_text: "未月结",
          key: 5,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "6月",
          status_text: "未月结",
          key: 6,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "7月",
          status_text: "未月结",
          key: 7,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "8月",
          status_text: "未月结",
          key: 8,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "9月",
          status_text: "未月结",
          key: 9,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "10月",
          status_text: "未月结",
          key: 10,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "11月",
          status_text: "未月结",
          key: 11,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
        {
          month: "12月",
          status_text: "未月结",
          key: 12,
          num: "--",
          total_cost_price: "--",
          price_tag: "--",
          gold_weight: "--",
          weight: "--",
        },
      ];
      getSettlementList({
        ...this.cond,
        hierarchy_id: this.hierarchy_id,
        year: new Date(this.year).getFullYear(),
      }).then((res) => {
        if (res.code === 1) {
          if (res.data.list.length !== 0) {
            this.loading = false;
            this.tableData.forEach((item) => {
              res.data.list.forEach((items) => {
                if (item.key === items.month) {
                  item.num = items.num;
                  item.total_cost_price = items.total_cost_price;
                  item.price_tag = items.price_tag;
                  item.gold_weight = items.gold_weight;
                  item.status_text = items.status_text;
                  item.weight = items.weight;
                  item.realname = items.realname;
                  item.create_time = items.create_time;
                }
              });
            });
          } else {
            this.loading = false;
          }
        }
      });
    },
    handelSettlement(type, row) {
      if (type === 10) {
        this.$confirm("确定要月结锁定吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          const data = {
            hierarchy_id: this.hierarchy_id,
            year: new Date(this.year).getFullYear(),
            month: row.key,
            status: type,
          };
          handelSettlement(data).then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "锁定成功！",
              });
              this.getSettlementList();
            }
          });
        });
      } else {
        this.$confirm("确定要解除月结吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          const data = {
            hierarchy_id: this.hierarchy_id,
            year: new Date(this.year).getFullYear(),
            month: row.key,
            status: type,
          };
          handelSettlement(data).then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "解除成功！",
              });
              this.getSettlementList();
            }
          });
        });
      }
    },
    handleSearch() {
      this.getSettlementList();
    },
    handleDetail(row) {
      const create_time = [];
      const hierarchy_id = [];
      create_time[0] =
        new Date(this.year).getFullYear() +
        "-" +
        row.key +
        "-" +
        new Date(new Date(this.year).getFullYear(), row.key, 0).getDate();
      create_time[1] =
        new Date(this.year).getFullYear() +
        "-" +
        row.key +
        "-" +
        new Date(new Date(this.year).getFullYear(), row.key, 0).getDate();
        if (storage.get("userInfo").hierarchy_type_id === 30) {
          hierarchy_id[0] = this.hierarchy_id;
        }
      this.$router.push({
        name: "HistoryInv",
        params: {
          create_time: create_time,
          hierarchy_id: hierarchy_id
        },
      });
    },
  },
};
</script>

<style lang="less">
/deep/ .el-loading-mask {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
</style>